import {
FETCH_PRODUCER_FORMS_PACKAGES_REQUEST,
FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS,
FETCH_PRODUCER_FORMS_PACKAGES_FAILURE,
REFRESH_PRODUCER_FORMS_PACKAGES,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID,
CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE,
POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS,
POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE,
CLEAR_PRODUCER_STATE_PRODUCT_ITEMS,
CREATE_DOMESTIC_SALES_REPORT_REQUEST,
CREATE_DOMESTIC_SALES_REPORT_SUCCESS,
CREATE_DOMESTIC_SALES_REPORT_FAILURE,
CREATE_EXEMPTION_REQUEST,
CREATE_EXEMPTION_SUCCESS,
CREATE_EXEMPTION_FAILURE,
} from './formsPackageTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchProducerFormsPackages = (companyUID) => {
  if (companyUID !== null) {
    const path = '/jsonapi/node/reporting_forms_package';
    const filter = `filter[company-filter][condition][path]=field_rctb_company.id&filter[company-filter][condition][value]=${companyUID}`;
    const sort = 'sort[package-sort][path]=field_season.field_season_start_date&sort[package-sort][direction]=DESC';
    const include = 'include=field_domestic_sales_report,field_exemption,field_season,field_invoice';
    const url = `${path}?${filter}&${sort}&${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchProducerFormsPackagesRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const producerFormsPackages = data.data;
          localStorage.setItem('producer_forms_packages', JSON.stringify(producerFormsPackages));
          dispatch(fetchProducerFormsPackagesSuccess(producerFormsPackages));
        }
        else {
          throw new Error(`message: ${response.status}`);
        }
      }
      catch (error) {
        const errorMsg = error.message;
        dispatch(fetchProducerFormsPackagesFailure(errorMsg));
      }
    }
  };
};

// export const fetchProducerFormsPackages = (companyUID, seasons) => {
//   return async (dispatch) => {
//     dispatch(fetchProducerFormsPackagesRequest());
//     let producerFormsPackages = [];
//     for (const season of seasons) {
//       const seasonProducerFormsPackages =
//         await fetchProducerFormsPackagesBySeason(companyUID, season.id);
//       if (seasonProducerFormsPackages !== null) {
//         producerFormsPackages.push(seasonProducerFormsPackages.data);
//       }
//     }
//     localStorage.setItem('producer_forms_packages', JSON.stringify(producerFormsPackages));
//     dispatch(fetchProducerFormsPackagesSuccess(producerFormsPackages));
//   };
// };

// export const fetchProducerFormsPackagesBySeason = async (
//   companyUID,
//   seasonID
// ) => {
//   if (companyUID !== null) {
//     const path = '/jsonapi/node/reporting_forms_package';
//     const filter = `filter[and-group][group][conjunction]=AND&filter[company-filter][condition][path]=field_rctb_company.id&filter[company-filter][condition][value]=${companyUID}&filter[company-filter][condition][memberOf]=and-group&filter[season-filter][condition][path]=field_season.id&filter[season-filter][condition][value]=${seasonID}&filter[season-filter][condition][memberOf]=and-group`;
//     const sort =
//       'sort[package-sort][path]=field_season.field_season_start_date&sort[package-sort][direction]=DESC';
//     const include = 'include=field_domestic_sales_report,field_exemption';
//     const url = `${path}?${filter}&${sort}&${include}`;
//     const options = {
//       method: 'GET',
//       headers: new Headers({
//         Accept: 'application/vnd.api+json',
//         'Content-Type': 'application/vnd.api+json',
//       }),
//       mode: 'cors',
//     };
//     try {
//       const response = await auth.fetchWithAuthentication(url, options);
//       if (response.ok) {
//         const data = await response.json();
//         const formsPackagesBySeason = data;
//         return formsPackagesBySeason;
//       } else {
//         return null;
//       }
//     } catch (error) {}
//   }
// };

export const postProducerStateProductItem = (data) => {
  const url = `/jsonapi/node/state_product`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        dispatch(postProducerStateProductItemSuccess(data.data));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(postProducerStateProductItemFailure(errorMsg));
    }
  };
};

export const postDomesticSalesReportFileItem = (file, fileName) => {
  // Maintaining this as a redux action, however, not using redux state.  Just return the file upload response
  const url = `/jsonapi/node/domestic_sales_report/field_domestic_sales_report_pdf`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`,
    }),
    mode: 'cors',
    body: file,
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const postExemptionRequestFileItem = (file, fileName) => {
  // Maintaining this as a redux action, however, not using redux state.  Just return the file upload response
  const url = `/jsonapi/node/exemption/field_exemption_request_pdf`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`,
    }),
    mode: 'cors',
    body: file,
  };
  return async (dispatch) => {
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const createDomesticSalesReport = (currentFormsPackageID, data) => {
  const url = `/jsonapi/node/domestic_sales_report`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createDomesticSalesReportRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createDomesticSalesReportSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        await dispatch(
          addDomesticSalesReportToPackage(newFormID, currentFormsPackageID)
        );
        history.push(
          `/dashboard/formCertification/domestic_sales_report/${newFormID}`
        );
      } else {
        dispatch(createDomesticSalesReportFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createDomesticSalesReportFailure(errorMsg));
    }
  };
};

export const addDomesticSalesReportToPackage = (formID, packageID) => {
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      data: [
        {
          type: 'node--domestic_sales_report',
          id: formID,
        },
      ],
    };
    const url = `/jsonapi/node/reporting_forms_package/${packageID}/relationships/field_domestic_sales_report`; //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          console.log('ADD TO PACKAGE OK');
        } else {
          //dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        //dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const createExemption = (currentFormsPackageID, data) => {
  const url = `/jsonapi/node/exemption`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createExemptionRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createExemptionSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newFormID = data.data.id;
        await dispatch(
          addExemptionToPackage(newFormID, currentFormsPackageID)
        );
        history.push(
          `/dashboard/formCertification/exemption/${newFormID}`
        );
      } else {
        dispatch(createExemptionFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createExemptionFailure(errorMsg));
    }
  };
};

export const addExemptionToPackage = (formID, packageID) => {
  if (formID !== null && packageID !== null) {
    let data = {};
    data = {
      data: [
        {
          type: 'node--exemption',
          id: formID,
        },
      ],
    };
    console.log("DATA: ", data);
    const url = `/jsonapi/node/reporting_forms_package/${packageID}/relationships/field_exemption`; //POST to a relationship resource will ADD the new node to the multi-value field.  Need to send as array.  PATCH will REPLACE existing field data.
    const options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          console.log('ADD TO PACKAGE OK');
        } else {
          //dispatch(amendFormFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        //dispatch(amendFormFailure(errorMsg));
      }
    };
  }
};

export const selectCurrentProducerFormsPackageBySeason = (seasonID) => {
  return {
    type: SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON,
    seasonID: seasonID,
  };
};

export const selectCurrentProducerFormsPackageByID = (rfpID) => {
  return {
    type: SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID,
    rfpID: rfpID,
  };
};

export const refreshProducerFormsPackages = () => {
  return {
    type: REFRESH_PRODUCER_FORMS_PACKAGES,
  };
};

export const clearCurrentProducerFormsPackage = () => {
  return {
    type: CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE,
  };
};

export const fetchProducerFormsPackagesRequest = () => {
  return {
    type: FETCH_PRODUCER_FORMS_PACKAGES_REQUEST,
  };
};

export const fetchProducerFormsPackagesSuccess = (producerFormsPackages) => {
  return {
    type: FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS,
    payload: producerFormsPackages,
  };
};

export const fetchProducerFormsPackagesFailure = (error) => {
  return {
    type: FETCH_PRODUCER_FORMS_PACKAGES_FAILURE,
    payload: error,
  };
};

export const postProducerStateProductItemSuccess = (postResult) => {
  return {
    type: POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS,
    payload: postResult,
  };
};

export const postProducerStateProductItemFailure = (error) => {
  return {
    type: POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE,
    payload: error,
  };
};

export const clearProducerStateProductItems = () => {
  return {
    type: CLEAR_PRODUCER_STATE_PRODUCT_ITEMS,
  };
};
 
export const createDomesticSalesReportRequest = () => {
  return {
    type: CREATE_DOMESTIC_SALES_REPORT_REQUEST,
  };
};

export const createDomesticSalesReportSuccess = (success) => {
  return {
    type: CREATE_DOMESTIC_SALES_REPORT_SUCCESS,
    payload: success.data,
  };
};

export const createDomesticSalesReportFailure = (error) => {
  return {
    type: CREATE_DOMESTIC_SALES_REPORT_FAILURE,
    payload: error,
  };
};

export const createExemptionRequest = () => {
  return {
    type: CREATE_EXEMPTION_REQUEST,
  };
};

export const createExemptionSuccess = (success) => {
  return {
    type: CREATE_EXEMPTION_SUCCESS,
    payload: success.data,
  };
};

export const createExemptionFailure = (error) => {
  return {
    type: CREATE_EXEMPTION_FAILURE,
    payload: error,
  };
};