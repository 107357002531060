import {
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAILURE,
  CHECK_COMPANY_PROFILE,
  // PATCH_COMPANY_REQUEST,
  // PATCH_COMPANY_SUCCESS,
  // PATCH_COMPANY_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
} from './companyTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchCompanyProfile = (companyUID) => {
  if (companyUID !== null) {
    const url = `/jsonapi/node/rctb_company/${companyUID}`;

    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return async (dispatch) => {
      dispatch(fetchCompanyProfileRequest);
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          const data = await response.json();
          const companyProfile = data.data;
          //console.log("COMPANY PROFILE DATA", companyProfile);
          const companyName = companyProfile['title'];
          const companyRCTBID = companyProfile['field_rctb_id'];
          const companyStatus = companyProfile['field_company_status'];
          const companyIsImporter =
            companyProfile['field_company_type'].includes('importer');
          const companyIsProducer =
            companyProfile['field_company_type'].includes('producer');
          localStorage.setItem('company_name', companyName);
          localStorage.setItem('company_rctb_id', companyRCTBID);
          localStorage.setItem('company_status', companyStatus);
          localStorage.setItem('company_is_importer', companyIsImporter);
          localStorage.setItem('company_is_producer', companyIsProducer);
          localStorage.setItem(
            'company_profile',
            JSON.stringify(companyProfile)
          );
          await dispatch(
            fetchCompanyProfileSuccess(
              companyName,
              companyRCTBID,
              companyStatus,
              companyIsImporter,
              companyIsProducer,
              companyProfile
            )
          );
        } else {
          throw new Error(`message: ${response.status}`);
        }
      } catch (error) {
        //console.log("COMPANY PROFILE FETCH FAILURE");
        const errorMsg = error.message;
        dispatch(fetchCompanyProfileFailure(errorMsg));
      }
    };
  }
};

export const fetchCompanyProfileRequest = () => {
  return {
    type: FETCH_COMPANY_PROFILE_REQUEST,
  };
};

export const fetchCompanyProfileSuccess = (
  companyName,
  companyRCTBID,
  companyStatus,
  companyIsImporter,
  companyIsProducer,
  companyProfile
) => {
  return {
    type: FETCH_COMPANY_PROFILE_SUCCESS,
    companyName: companyName,
    companyRCTBID: companyRCTBID,
    companyStatus: companyStatus,
    companyIsImporter: companyIsImporter,
    companyIsProducer: companyIsProducer,
    companyProfile: companyProfile,
  };
};

export const fetchCompanyProfileFailure = (error) => {
  return {
    type: FETCH_COMPANY_PROFILE_FAILURE,
    payload: error,
  };
};

export const checkCompanyProfile = () => {
  return {
    type: CHECK_COMPANY_PROFILE,
    companyName: localStorage.getItem('company_name'),
    companyRCTBID: localStorage.getItem('company_rctb_id'),
    companyStatus: localStorage.getItem('company_status'),
    companyIsImporter: localStorage.getItem('company_is_importer'),
    companyIsProducer: localStorage.getItem('company_is_producer'),
    companyProfile: JSON.parse(localStorage.getItem('company_profile')),
    // companies: JSON.parse(localStorage.getItem('companies')),
  };
};

// export const patchCompanyRequest = () => {
//   return {
//     type: PATCH_COMPANY_REQUEST,
//   };
// };

// export const patchCompanySuccess = () => {
//   return {
//     type: PATCH_COMPANY_SUCCESS,
//   };
// };

// export const patchCompanyFailure = () => {
//   return {
//     type: PATCH_COMPANY_FAILURE,
//   };
// };

const companies = [];

export const fetchCompanies = () => {
  const path = '/jsonapi/node/rctb_company';
  const filter = `filter[and-group][group][conjunction]=AND&filter[producer-filter][condition][path]=field_company_type&filter[producer-filter][condition][value]=producer&filter[producer-filter][condition][memberOf]=and-group&filter[status-filter][condition][path]=field_company_status&filter[status-filter][condition][value]=active&filter[status-filter][condition][memberOf]=and-group&page[limit]=500`;
  const url = `${path}?${filter}`;

  return (dispatch) => {
    fetchCompaniesPage(url, dispatch);
    // console.log("COMPANIES: ", companies);
  }
}

export const fetchCompaniesPage = (url, dispatch) => {

  const options = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };

  dispatch(fetchCompaniesRequest);
  auth
    .fetchWithAuthentication(url, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      companies.push(...data.data);
      dispatch(fetchCompaniesSuccess(companies));

      const hasNextPage = data.links.hasOwnProperty("next");
      if (hasNextPage) {
        const nextPageHref = decodeURIComponent(data.links.next.href);
        const nextPageUrl = '/' + nextPageHref.split('/').slice(3).join('/'); //remove base url from link
        fetchCompaniesPage(nextPageUrl, dispatch);
      }

    })
    .catch((error) => {
      const errorMsg = error.message;
      console.log("ERROR: ", errorMsg);
      dispatch(fetchCompaniesFailure(errorMsg));
    });
};

export const fetchCompaniesRequest = () => {
  return {
    type: FETCH_COMPANIES_REQUEST,
  };
};

export const fetchCompaniesSuccess = (companies) => {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies: companies,
  };
};

export const fetchCompaniesFailure = (error) => {
  return {
    type: FETCH_COMPANIES_FAILURE,
    payload: error,
  };
};
