import {
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  CHECK_USER_PROFILE,
  SET_USER_COMPANY_UID,
  FETCH_PROXY_REQUEST,
  FETCH_PROXY_SUCCESS,
  FETCH_PROXY_FAILURE,
  CHECK_PROXY,
} from './userTypes';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchUserProfile = (username) => {
  const url = `/jsonapi/user/user?filter[name][value]=${username}`;

  const options = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const userProfile = data.data;
        const userUID = userProfile[0]['id'];
        const userFirstName = userProfile[0]['field_first_name'];
        const companyUID = userProfile[0]['field_rctb_company']['id'];
        localStorage.setItem('username', username);
        localStorage.setItem('user_uid', userUID);
        localStorage.setItem('company_uid', companyUID);
        localStorage.setItem('user_firstname', userFirstName);
        dispatch(
          fetchUserProfileSuccess(username, userUID, userFirstName, companyUID)
        );
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchUserProfileFailure(errorMsg));
    }
  };
};

export const fetchProxy = () => {
  const url = `/jsonapi/config_pages/proxy_configuration`;

  const options = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
  };
  return async (dispatch) => {
    dispatch(fetchProxyRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        const data = await response.json();
        const proxyData = data.data;
        let proxy = proxyData[0]['field_proxy_users'];
        if (!Array.isArray(proxy)) {
          proxy = [];
        }
        localStorage.setItem('proxy', JSON.stringify(proxy));
        dispatch(fetchProxySuccess(proxy));
      } else {
        throw new Error(`message: ${response.status}`);
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchProxyFailure(errorMsg));
    }
  };
};

export const setUserCompanyUID = (companyUID) => {
  return {
    type: SET_USER_COMPANY_UID,
    companyUID: companyUID,
  };
};

export const fetchUserProfileRequest = () => {
  return {
    type: FETCH_USER_PROFILE_REQUEST,
  };
};

export const fetchUserProfileSuccess = (
  username,
  userUID,
  userFirstName,
  companyUID
) => {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    username: username,
    userUID: userUID,
    userFirstName: userFirstName,
    companyUID: companyUID,
  };
};

export const fetchUserProfileFailure = (error) => {
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    payload: error,
  };
};

export const checkUserProfile = () => {
  return {
    type: CHECK_USER_PROFILE,
    username: localStorage.getItem('username'),
    userUID: localStorage.getItem('user_uid'),
    userFirstName: localStorage.getItem('user_firstname'),
    companyUID: localStorage.getItem('company_uid'),
  };
};

export const fetchProxyRequest = () => {
  return {
    type: FETCH_PROXY_REQUEST,
  };
};

export const fetchProxySuccess = (proxy) => {
  return {
    type: FETCH_PROXY_SUCCESS,
    proxy: proxy,
  };
};

export const fetchProxyFailure = (error) => {
  return {
    type: FETCH_PROXY_FAILURE,
    payload: error,
  };
};

export const checkProxy = () => {
  return {
    type: CHECK_PROXY,
    proxy: JSON.parse(localStorage.getItem('proxy')),
  };
};
