import {
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE,
  PATCH_INVOICE_REQUEST,
  PATCH_INVOICE_SUCCESS,
  PATCH_INVOICE_FAILURE,
} from './invoiceTypes';

const initialState = {
  loading: false,
  invoice: null,
  error: '',
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        error: '',
      };
    case FETCH_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        invoice: null,
        error: action.payload,
      };
    case PATCH_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        error: '',
      };
    case PATCH_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        invoice: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceReducer;