export const FETCH_COMPANY_PROFILE_REQUEST = 'FETCH_COMPANY_PROFILE_REQUEST';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const FETCH_COMPANY_PROFILE_FAILURE = 'FETCH_COMPANY_PROFILE_FAILURE';
export const CHECK_COMPANY_PROFILE = 'CHECK_COMPANY_PROFILE';
// export const PATCH_COMPANY_REQUEST = 'PATCH_COMPANY_REQUEST';
// export const PATCH_COMPANY_SUCCESS = 'PATCH_COMPANY_SUCCESS';
// export const PATCH_COMPANY_FAILURE = 'PATCH_COMPANY_FAILURE';
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
