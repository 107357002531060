import {
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILURE,
  PATCH_INVOICE_REQUEST,
  PATCH_INVOICE_SUCCESS,
  PATCH_INVOICE_FAILURE,
} from './invoiceTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const fetchInvoice = (invoiceUID) => {

  if (invoiceUID !== null) {

    //fetch a single invoice
    const path = `/jsonapi/node/invoice/${invoiceUID}`;
    const include = `include=field_season,field_qb_invoice_pdf`;
    const url = `${path}?${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return (dispatch) => {
      dispatch(fetchInvoiceRequest());
      auth
        .fetchWithAuthentication(url, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const invoice = data;
          dispatch(fetchInvoiceSuccess(invoice));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(fetchInvoiceFailure(errorMsg));
        });
    };
  }
};

export const fetchInvoiceRequest = () => {
  return {
    type: FETCH_INVOICE_REQUEST,
  };
};

export const fetchInvoiceSuccess = (invoice) => {
  return {
    type: FETCH_INVOICE_SUCCESS,
    payload: invoice.data,
  };
};

export const fetchInvoiceFailure = (error) => {
  return {
    type: FETCH_INVOICE_FAILURE,
    payload: error,
  };
};

export const patchInvoice = (invoiceUID, data) => {

  if (invoiceUID !== null) {

    //patch a single invoice
    const url = `/jsonapi/node/invoice/${invoiceUID}`;
    const options = {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
      body: JSON.stringify(data),
    };
    return async (dispatch) => {
      dispatch(patchInvoiceRequest());
      try {
        const response = await auth.fetchWithAuthentication(url, options);
        if (response.ok) {
          dispatch(patchInvoiceSuccess(JSON.stringify(response)));
          history.push(`/dashboard`);
        } else {
          dispatch(patchInvoiceFailure('An error has occured.'));
        }
      } catch (error) {
        const errorMsg = error.message;
        dispatch(patchInvoiceFailure(errorMsg));
      }
    };
  }
};

export const patchInvoiceRequest = () => {
  return {
    type: PATCH_INVOICE_REQUEST,
  };
};

export const patchInvoiceSuccess = (invoice) => {
  return {
    type: PATCH_INVOICE_SUCCESS,
    payload: invoice.data,
  };
};

export const patchInvoiceFailure = (error) => {
  return {
    type: PATCH_INVOICE_FAILURE,
    payload: error,
  };
};
