import {
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  CHECK_USER_PROFILE,
  SET_USER_COMPANY_UID,
  FETCH_PROXY_REQUEST,
  FETCH_PROXY_SUCCESS,
  FETCH_PROXY_FAILURE,
  CHECK_PROXY,
} from './userTypes';

const initialState = {
  loading: false,
  username: null,
  userUID: null,
  userFirstName: null,
  companyUID: null,
  proxy: [],
  error: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        username: action.username,
        userUID: action.userUID,
        userFirstName: action.userFirstName,
        companyUID: action.companyUID,
        error: '',
      };
    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHECK_USER_PROFILE:
      return {
        ...state,
        username: action.username,
        userUID: action.userUID,
        userFirstName: action.userFirstName,
        companyUID: action.companyUID,
      };
    case SET_USER_COMPANY_UID:
      return {
        ...state,
        companyUID: action.companyUID,
      };
    case FETCH_PROXY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROXY_SUCCESS:
      return {
        ...state,
        loading: false,
        proxy: action.proxy,
      };
    case FETCH_PROXY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHECK_PROXY:
      return {
        ...state,
        proxy: action.proxy,
      };
    default:
      return state;
  }
};

export default userReducer;
