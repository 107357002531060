import {
  FETCH_FORM_REQUEST,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_FAILURE,
  CERTIFY_FORM_REQUEST,
  CERTIFY_FORM_SUCCESS,
  CERTIFY_FORM_FAILURE,
  SET_CERTIFIED_FORM_TYPE,
  REFRESH_CERTIFIED_FORM_TYPE,
  RESET_CERTIFIED_FORM_TYPE,
} from './formTypes';

const initialState = {
  loading: false,
  form: [],
  certifiedFormType: [],
  error: '',
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        form: action.payload,
        error: '',
      };
    case FETCH_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        form: [],
        error: action.payload,
      };
    case CERTIFY_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CERTIFY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case CERTIFY_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CERTIFIED_FORM_TYPE:
      return {
        ...state,
        certifiedFormType: action.payload,
      };
    case REFRESH_CERTIFIED_FORM_TYPE:
      const storedCertifiedFormType = localStorage.getItem(
        'new_form_certification_type'
      );
      let formTypeResult = [];
      if (storedCertifiedFormType && storedCertifiedFormType.length > 0) {
        formTypeResult = storedCertifiedFormType;
      }
      return {
        ...state,
        certifiedFormType: formTypeResult,
      };
    case RESET_CERTIFIED_FORM_TYPE:
      localStorage.removeItem('new_form_certification_type');
      return {
        ...state,
        certifiedFormType: [],
      };
    default:
      return state;
  }
};

export default formReducer;
